@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-2xl;
    }

    h2 {
        @apply text-xl;
    }

    h3 {
        @apply text-lg;
    }

    h4 {
        @apply text-base;
    }
}

@font-face {
    font-family: Switzer;
    src: url(./fonts/Switzer-Regular.otf) format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: Switzer;
    src: url(./fonts/Switzer-Extralight.otf) format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: Switzer;
    src: url(./fonts/Switzer-Bold.otf) format("opentype");
    font-weight: 600;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Switzer';
    color: #fff;
    /* font-size: 16px; */
    /* font-style: normal;
    font-weight: 400;
    line-height: 32px; */
}

label {
    font-size: 14px;
    font-weight: 300;
    color: #fff;
}

body {
    background: linear-gradient(180deg, #343E4F 0%, #462347 100%);
    height: 100vh;
}

.btn {
    border-radius: 10px;
    background: #1F73A2;
    width: 139px;
    font-size: 14px;
    color: #fff;
    border: none;
    cursor: pointer;
}

.btn-1 {
    width: 100%;
    border-radius: 60px;
}

.btn-2 {
    width: 338px;
    height: 44px;
    border-radius: 10px;
    margin-top: 22px;
}

.btn-align-right {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: end;
}

.btn-3 {
    width: 159px;
    height: 32px;
    border-radius: 10px;
}

.btn-4 {
    width: 100%;
    height: 58px;
    border-radius: 10px;
    background-color: #2F80ED;
}

/*Custom components*/
.mobile-view {
    /*width: 64px !important;*/
    padding-left: 10px !important;
    padding-right: 10px !important;

    p {
        display: none;
    }

    .hamburger {
        justify-content: center;
    }
}


/* Paddings */
.padding-top-24 {
    padding-top: 24px;
}


.clickable {
    cursor: pointer;
}

input[type="file"]::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
}

.margin-top-24 {
    margin-top: 24px;
}

/*Paddings*/
.pl-2 {
    padding-left: 8px !important;
}

.pr-2 {
    padding-right: 8px !important;
}

.chip-DRAFT {
    background: #F2C94C;
    color: #333333;
}

.chip-FINAL {
    background: #27AE60;
    color: #333333;
}

.chip-REJECTED {
    background: #EB5757;
    color: #333333;
}

.responsive-canvas {
    width: 100%;
    height: auto;
}


ol {

    padding-left: 20px;

    li {
        list-style-type: decimal;
    }
}


@media (max-width: 900px) {
    .ant-modal, .ant-modal-content {
        height: 100vh;
        width: 100vw;
        margin: 0 !important;
        top: 0;
    }

    .ant-modal-body {
        height: calc(100vh - 110px);
    }

}

.whiteMode {
    * {
        color: black !important;
    }
}
