.vertical-layout > * {
    min-width: 364px;
}

.oswn-table {
    td {
        padding-bottom: 24px;
        padding-top: 24px;
    }

    td:first-child {
        padding-left: 24px;
    }

    td:last-child {
        padding-right: 24px;
    }
}


.float {
}

.tab-buttons {

    position: fixed;
    bottom: 40px;
    color: #FFF;
    text-align: center;
    min-width: 310px;

    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    max-width: fit-content;
    background-color: #3a3a3a;
    border-radius: 8px;

    button {
        background-color: #9a3c8b;

        &:hover {
            background-color: #6e2c64;
        }


        padding: 8px;
        border-radius: 8px;
        font-size: 16px;
    }

    .selected {
        background-color: #6e2c64;
    }
}

.tab-widget-buttons {
    text-align: center;


    button {
        background-color: #ffffff;

        &:hover {
            background-color: #9a3c8b;
            color: #ffffff;
        }


        padding: 8px;
        border-radius: 8px;
        font-size: 16px;
    }

    .selected {
        background-color: #9a3c8b;
        color: #ffffff;
    }
}

.content-wrapper {
    position: relative;
}

.content-enter {
    opacity: 0;
}

.content-enter-active {
    opacity: 1;
    transition: opacity 0.25s linear;
    transition-delay: 0.25s
}

.content-enter-done {
    opacity: 1;
}

.content-exit {
    position: absolute;
    top: 0;
    opacity: 1;
}

.content-exit-active {
    opacity: 0;
    transition: opacity 0.25s linear;
}

.content-exit-done {
    opacity: 0;
}